import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Box, Heading } from "grommet"
import Card from "./Card"

const Appearances = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/appearances/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              link
              featuredimage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
          }
        }
      }
    }
  `)
  return (
    <Box background="#F6F9FC" id="appearances">
      <Heading color="brand" alignSelf="center">
        Appearances
      </Heading>
      <Box
        direction="row"
        wrap={true}
        alignSelf="center"
        alignContent="between"
        justify="around"
      >
        {data.allMarkdownRemark.edges.map(a => (
          <Card
            key={a.node.frontmatter.title}
            title={a.node.frontmatter.title}
            cardImage={a.node.frontmatter.featuredimage.childImageSharp.fluid}
            body={a.node.html}
            link={a.node.frontmatter.link}
          />
        ))}
      </Box>
    </Box>
  )
}

export default Appearances
