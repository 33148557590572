import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Box, Heading } from "grommet"
import MaxWidth from "./Common/MaxWidth"

const Reel = () => {
 

  const data = useStaticQuery(graphql`
    query ReelVideo {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/video/" } }) {
        edges {
          node {
            id
            frontmatter {
              height
              width
              source
            }
          }
        }
      }
    }
  `)
  const cmsData = data.allMarkdownRemark.edges[0].node.frontmatter
  return (
    <Box background="brand" id="reel">
      <Heading color="white" alignSelf="center">
        Reel
      </Heading>
      <MaxWidth>
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            paddingTop: "56.25%",
            margin: "0 1vw 1vh 1vw",
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: 0,
            }}
            src={cmsData.source}
            title="Edward Manley Reel"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
          />
        </div>
      </MaxWidth>
    </Box>
  )
}

export default Reel
