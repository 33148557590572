import React from "react"
import { Link } from "gatsby"

import "./NavMenu.styles.css"

const NavLinks = (name, clickFunction) => {
  const linkArray = [
    <Link
      to="/#appearances"
      key="appearancesLink"
      title="appearancesLink"
      className={name}
      onClick={clickFunction}
    >
      Appearances
    </Link>,

    <Link
      to="/#reel"
      key="reelLink"
      title="reelLink"
      className={name}
      onClick={clickFunction}
    >
      Reel
    </Link>,

    <Link
      to="/#gallery"
      key="galleryLink"
      title="galleryLink"
      className={name}
      onClick={clickFunction}
    >
      Gallery
    </Link>,

    <Link
      to="/#contact"
      key="contactLink"
      title="contactLink"
      className={name}
      onClick={clickFunction}
    >
      Contact
    </Link>,
  ]
  return linkArray
}

export default NavLinks
