import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Box, Heading, Markdown } from "grommet"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query ContactData {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/contact/" } }) {
        edges {
          node {
            id
            frontmatter {
              rep
              email
            }
          }
        }
      }
    }
  `)
  return (
    <Box background="#F6F9FC" fill={true} alignContent="center" id="contact">
      <Box
        background="white"
        alignSelf="center"
        elevation="medium"
        width={{ max: "800px" }}
        margin="large"
        pad="medium"
      >
        <Heading
          level={2}
          margin="small"
          color="brand"
          alignSelf="center"
        >
          Contact
        </Heading>
        <Heading level={3} color="brand" margin="small">
          Agent
        </Heading>
        <Markdown align="center">
          {data.allMarkdownRemark.edges[0].node.frontmatter.rep}
        </Markdown>
        <Heading level={3} color="brand">
          Edward
        </Heading>
        <Heading level={4} alignSelf="center">
          {data.allMarkdownRemark.edges[0].node.frontmatter.email}
        </Heading>
      </Box>
    </Box>
  )
}

export default Contact
