import React from "react"

import { Box, Text, Anchor } from "grommet"

const FootBar = () => {
  return (
    <Box background="brand">
      <Box
        background="brand"
        direction="column"
        align="baseline"
        margin={{ top: "large", bottom: "5px", left: "10px" }}
      >
        <Text
          size="small"
          color="white"
        >{`Edward Manley © ${new Date().getFullYear()}`}</Text>
        <Anchor
          size="small"
          color="white"
          target="_blank"
          rel="noreferrer"
          href="https://www.manleyweb.dev"
          label="site by manleyweb.dev"
        />
      </Box>
    </Box>
  )
}

export default FootBar
