import React from "react"
import { Box, Heading, Markdown } from "grommet"
import Img from "gatsby-image"

const Card = props => (
  <a
    target="_blank"
    rel="noreferrer"
    href={props.link}
    style={{ textDecoration: "none" }}
  >
    <Box
      background="white"
      elevation="small"
      margin="medium"
      height="300px"
      key={props.title}
    >
      <Heading level={2} color="brand" alignSelf="center" margin="small">
        {props.title}
      </Heading>
      <Box direction="row" fill={true} alignSelf="center" align="center">
        <Img
          fluid={props.cardImage}
          alt={`${props.title} poster`}
          style={{ width: "100%", margin: "10px 10px" }}
        />
        <Box style={{minWidth: "150px"}} margin="small">
          <Markdown style={{minWidth: "150px"}}>{props.body}</Markdown>
        </Box>
      </Box>
    </Box>
  </a>
)

export default Card
