import React from "react"
import { Grommet } from "grommet"

import SEO from "../components/seo"
import Navbar from "../components/Navbar"
import Headshots from "../components/Headshots"
import Appearances from "../components/Appearances.js"
import Reel from "../components/Reel"
import Gallery from "../components/Gallery"
import Contact from "../components/Contact"
import FootBar from "../components/Footer"

//global styles
import "./styles.css"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const myTheme = {
  global: {
    font: {
      family: "Roboto",
    },
    colors: {
      brand: "#02203B",
      focus: "dark-6",
      text: {
        dark: "brand",
      },
    },
  },
  heading: {
    weight: 400,
    font: {
      family: "PT Serif",
    },
  },
  carousel: {
    animation: {
      duration: 500,
    },
    icons: {
      color: "brand",
      border: {
        size: 0,
        color: "none",
      },
    },
    disabled: {
      icons: {
        color: "grey",
      },
    },
  },
  button: {
    active: {
      border: {
        color: "#666666",
      },
    },
  },
}

const IndexPage = () => (
  <Grommet theme={myTheme}>
    <SEO title="Home" />
    <Navbar />
    <Headshots />
    <Appearances />
    <Reel />
    <Gallery />
    <Contact />
    <FootBar />
  </Grommet>
)

export default IndexPage
