import React, { useState, useEffect } from "react"
import { Header, Heading, Box, Button, Collapsible } from "grommet"
import { Menu, Close } from "grommet-icons"
import { Link } from "gatsby";
import MaxWidth from "./Common/MaxWidth"
import NavLinks from "./NavMenu/NavLinks"

const Navbar = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false)
  const [show, setShow] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 900px)")
    mediaQuery.addListener(handleMediaQueryChange)
    handleMediaQueryChange(mediaQuery)
  }, [])

  const handleMediaQueryChange = mediaQuery => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }

  const NavLinkClick = () => {
    setShow(false);
  }
  const NavButton = () => {
    return (
      <>
        {show ? (
          <Button
            icon={<Close color="white" />}
            onClick={() => setShow(false)}
          />
        ) : (
          <Button icon={<Menu color="white" />} onClick={() => setShow(true)} />
        )}
      </>
    )
  }

  const SmallNavbar = () => (
    <Box
      background="brand"
      elevation="xlarge"
      style={{ position: "sticky", top: 0, width: "100%", zIndex: 5 }}
    >
      <Header background="brand">
        <Box alignContent="center" fill="horizontal">
          <MaxWidth>
            <Box direction="row" justify="between" align="baseline">
              <Link to="/" style={{textDecoration: "none"}}>
                <Heading level={2} margin="small" color="white">
                  EDWARD MANLEY
                </Heading>
              </Link>
              <NavButton />
            </Box>
          </MaxWidth>
        </Box>
      </Header>
      <Collapsible direction="vertical" open={show}>
        <Box margin="medium" direction="column" alignSelf="center" gap="medium" align="center" background="brand">
          {NavLinks("navLinkLight", NavLinkClick)}
        </Box>
      </Collapsible>
    </Box>
  )

  const LargeNavbar = () => (
    <Header
      background="brand"
      elevation="xlarge"
      style={{ position: "sticky", top: 0, width: "100%", zIndex: 5 }}
    >
      <Box alignContent="center" fill="horizontal">
        <MaxWidth>
          <Box direction="row" justify="between" align="baseline">
            <Link to="/" style={{textDecoration: "none"}}>
              <Heading level={2} margin="small" color="white">
                EDWARD MANLEY
              </Heading>
            </Link>
            <Box direction="row" gap="small" margin="small">
              {NavLinks("navLinkLight")}
            </Box>
          </Box>
        </MaxWidth>
      </Box>
    </Header>
  )

  return <>{isSmallScreen ? SmallNavbar() : LargeNavbar()}</>
}

export default Navbar
