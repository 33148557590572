import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Box, Heading } from "grommet"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query GalleryQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/gallery/" } }
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            id
            frontmatter {
              galleryimage {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              title
            }
          }
        }
      }
    }
  `)
  return (
    <Box background="#666666" id="gallery">
      <Heading color="white" alignSelf="center">
        Gallery
      </Heading>

      <Box
        direction="row"
        alignSelf="center"
        align="center"
        alignContent="center"
        justify="around"
        wrap={true}
      >
        {data.allMarkdownRemark.edges.map(e => (
          <Box
            margin="medium"
            border={{
              color: "white",
              size: "medium",
              style: "solid",
              side: "all",
            }}
            width="350px"
            style={{maxWidth: "350px" ,maxHeight: "400px"}}
            key={e.node.frontmatter.title}
          >
            <Img
              fluid={e.node.frontmatter.galleryimage.childImageSharp.fluid}
              alt="gallery image"
              style={{ width: "100%" }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default Gallery
