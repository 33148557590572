import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Box } from "grommet"

const Headshots = () => {
  const data = useStaticQuery(graphql`
    query carouselImages {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/carousel/" } }) {
        edges {
          node {
            id
            frontmatter {
              image1 {
                childImageSharp {
                  fluid(quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
 
  const pic = data.allMarkdownRemark.edges[0].node.frontmatter.image1.childImageSharp.fluid;
  return (
    <Box background="#666666" alignContent="center">
      <Box alignSelf="center" width={{ max: "800px" }} fill="horizontal">
      <Img
              fluid={pic}
              fadeIn={false}
              loading="eager"
              alt={pic.src.slice(-15, -5)}
              key={pic.src.slice(-5)}
            />
      </Box>
    </Box>
  )
}

export default Headshots
